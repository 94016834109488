import _ from 'lodash';

const parseFilters = obj => {
  const result = Object.keys(obj).reduce((acc, prev) => {
    if (obj[prev] === 0 || obj[prev]) {
      acc.push(
        `${prev}=${Array.isArray(obj[prev]) ? encodeURIComponent(obj[prev].join(',')) : encodeURIComponent(obj[prev])}`,
      );
    }
    return acc;
  }, []);

  return result.join('&');
};

const parseFields = arr => arr.join(',');

const parseFieldsAndFilters = (fields, filters) => ({
  fields: parseFields(fields),
  filters: parseFilters(filters),
});

const constructUrl = (base, config) => {
  const {
    method = 'GET',
    fields = [],
    filters = {},
    offset = 0,
    limit = config?.limit ? config.limit : 25,
    queryParams,
    pagination = true,
    isOffetLimit = false,
  } = config;
  let parsedUrl = base;
  const parsedFields = parseFields(fields);
  const parsedFilters = parseFilters(filters);

  if (!_.isEmpty(parsedFields)) {
    parsedUrl = `${parsedUrl}?fields=${parsedFields}`;
  }

  if (!_.isEmpty(parsedFilters)) {
    parsedUrl = `${parsedUrl}${
      !_.isEmpty(fields) ? '&' : '?'
      }${parsedFilters}`;
  }

  if (method === 'GET' && pagination) {
    const { page, perPage } = pagination;
    
    const _offset = ( page === 1 ? 0 : isOffetLimit ? page : ( page - 1 ) * perPage ) || offset;
    
    const _limit = perPage || limit;
    parsedUrl = `${parsedUrl}${
      !_.isEmpty(fields) || !_.isEmpty(filters) ? '&' : '?'
      }offset=${_offset}&limit=${_limit}`;
  }

  if (queryParams) {
    const queryString = pagination ? `&${queryParams}` : `?${queryParams}`;
    parsedUrl += queryString;
  }
  return parsedUrl;
};

export { parseFields, parseFilters, parseFieldsAndFilters, constructUrl };
